@import "../../Contrib/Splide/splide-core.min";

// Carousel

.image-slider {
    visibility: hidden;
    height: 275px;

    @include media-breakpoint-up(md){
        height: 630px;
    }
}

.header-image {
    .splide__slide {
        height: 100%;
    }

    .splide__list {
        padding-left: 0;
    }
}



.header-splide,
.header-image {
    margin-top: 120px;

    @include media-breakpoint-up(lg){
        margin-top: 180px;
    }
}

.header-splide {
    picture {
        display: block;
        height: 100%;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.header-image, .splide__list, .frame-type-wvier_headerslider .splide__track {
    height: 100%;
}

/* Dots */

.splide__pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    list-style: none;
    display: none !important;
    flex-wrap: wrap;
    text-align: center;
    padding: 0;
    justify-content: center;
    margin-bottom: 1.5325rem !important;
    margin-left: 10%;
    margin-right: 10%;
    max-width: 100%;
    z-index: 1025;

    @include media-breakpoint-up(lg) {
        display: flex !important;
    }

    li {
        box-sizing: content-box;
        flex: 0 1 auto;
        margin: 3px !important;
        padding: 0 !important;
        cursor: pointer;

        &::before {
            content: '' !important;
        }

        button {
            display: block;
            border: 0;
            background-color: $white;
            opacity: 0.28;
            height: 5px;
            width: 25px;
            outline: none;
            line-height: 0;
            font-size: 0;
            text-indent: -999px;
            color: transparent;
            padding: 0;
            cursor: pointer;

            &:hover, &:focus {
                outline: none;
                opacity: 0.72;
            }

            &.is-active {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 2rem !important;

        li {
            margin: 5px !important;

            button {
                width: 30px;
            }
        }
    }
}

html[lang="de"] .page-1 {
    .image-slider {
        &.header-splide {
            &::after {
                display: flex;
                align-items: center;
                content: " ";
                position: absolute;
                right: 0;
                bottom: -25px;
                width: 340px;
                height: 169px;
                opacity: 0;
                background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/zeit-nehmen.webp");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;

                @include media-breakpoint-up(sm) {
                    width: 500px;
                    height: 249px;
                    bottom: -40px;
                }

                @include media-breakpoint-up(lg) {
                    width: 800px;
                    height: 398px;
                    right: 5%;
                    bottom: -55px;
                }
            }
        }
    }
}

html[lang="de"].no-webp .page-1 {
    .image-slider {
        &.header-splide {
            &::after {
                background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/zeit-nehmen.png");
            }
        }
    }
}

html[lang="en"] .page-1 {
    .image-slider {
        &.header-splide {
            &::after {
                display: flex;
                align-items: center;
                content: " ";
                position: absolute;
                right: 0;
                bottom: -25px;
                width: 340px;
                height: 169px;
                opacity: 0;
                background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/take-time.webp");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: right;

                @include media-breakpoint-up(sm) {
                    width: 500px;
                    height: 249px;
                    bottom: -40px;
                }

                @include media-breakpoint-up(lg) {
                    width: 800px;
                    height: 398px;
                    right: 5%;
                    bottom: -55px;
                }
            }
        }
    }
}

html[lang="en"].no-webp .page-1 {
    .image-slider {
        &.header-splide {
            &::after {
                background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/take-time.png");
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .frame-type-wvier_carousel > .container {
        padding-left: 0;
        padding-right: 0;
    }
}
