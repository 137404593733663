@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}

.splide__container {
  position: relative;
  box-sizing: border-box;
}

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: -webkit-max-content;
  width: max-content;
  will-change: transform;
}

.splide.is-active .splide__list {
  display: flex;
}

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0;
}

.splide {
  visibility: hidden;
}

.splide, .splide__slide {
  position: relative;
  outline: none;
}

.splide__slide {
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  flex-shrink: 0;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #999;
  border-left-color: transparent;
  animation: splide-loading 1s linear infinite;
}

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  -webkit-user-select: none;
  user-select: none;
}

.splide--fade > .splide__track > .splide__list {
  display: block;
}

.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}

.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide--ttb > .splide__track > .splide__list {
  display: block;
}

.splide--ttb > .splide__pagination {
  width: auto;
}

.image-slider {
  visibility: hidden;
  height: 275px;
}

@media (min-width: 768px) {
  .image-slider {
    height: 630px;
  }
}

.header-image .splide__slide {
  height: 100%;
}

.header-image .splide__list {
  padding-left: 0;
}

.header-splide,
.header-image {
  margin-top: 120px;
}

@media (min-width: 992px) {
  .header-splide,
  .header-image {
    margin-top: 180px;
  }
}

.header-splide picture {
  display: block;
  height: 100%;
}

.header-splide picture img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.header-image, .splide__list, .frame-type-wvier_headerslider .splide__track {
  height: 100%;
}

/* Dots */
.splide__pagination {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  list-style: none;
  display: none !important;
  flex-wrap: wrap;
  text-align: center;
  padding: 0;
  justify-content: center;
  margin-bottom: 1.5325rem !important;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 100%;
  z-index: 1025;
}

@media (min-width: 992px) {
  .splide__pagination {
    display: flex !important;
  }
}

.splide__pagination li {
  box-sizing: content-box;
  flex: 0 1 auto;
  margin: 3px !important;
  padding: 0 !important;
  cursor: pointer;
}

.splide__pagination li::before {
  content: '' !important;
}

.splide__pagination li button {
  display: block;
  border: 0;
  background-color: #fff;
  opacity: 0.28;
  height: 5px;
  width: 25px;
  outline: none;
  line-height: 0;
  font-size: 0;
  text-indent: -999px;
  color: transparent;
  padding: 0;
  cursor: pointer;
}

.splide__pagination li button:hover, .splide__pagination li button:focus {
  outline: none;
  opacity: 0.72;
}

.splide__pagination li button.is-active {
  opacity: 1;
}

@media (min-width: 1420px) {
  .splide__pagination {
    margin-bottom: 2rem !important;
  }
  .splide__pagination li {
    margin: 5px !important;
  }
  .splide__pagination li button {
    width: 30px;
  }
}

html[lang="de"] .page-1 .image-slider.header-splide::after {
  display: flex;
  align-items: center;
  content: " ";
  position: absolute;
  right: 0;
  bottom: -25px;
  width: 340px;
  height: 169px;
  opacity: 0;
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/zeit-nehmen.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

@media (min-width: 576px) {
  html[lang="de"] .page-1 .image-slider.header-splide::after {
    width: 500px;
    height: 249px;
    bottom: -40px;
  }
}

@media (min-width: 992px) {
  html[lang="de"] .page-1 .image-slider.header-splide::after {
    width: 800px;
    height: 398px;
    right: 5%;
    bottom: -55px;
  }
}

html[lang="de"].no-webp .page-1 .image-slider.header-splide::after {
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/zeit-nehmen.png");
}

html[lang="en"] .page-1 .image-slider.header-splide::after {
  display: flex;
  align-items: center;
  content: " ";
  position: absolute;
  right: 0;
  bottom: -25px;
  width: 340px;
  height: 169px;
  opacity: 0;
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/take-time.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

@media (min-width: 576px) {
  html[lang="en"] .page-1 .image-slider.header-splide::after {
    width: 500px;
    height: 249px;
    bottom: -40px;
  }
}

@media (min-width: 992px) {
  html[lang="en"] .page-1 .image-slider.header-splide::after {
    width: 800px;
    height: 398px;
    right: 5%;
    bottom: -55px;
  }
}

html[lang="en"].no-webp .page-1 .image-slider.header-splide::after {
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Images/take-time.png");
}

@media (max-width: 1419.98px) {
  .frame-type-wvier_carousel > .container {
    padding-left: 0;
    padding-right: 0;
  }
}
